
import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Helmet } from "react-helmet"

import Footer from "./Footer"

import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  //Check if window is defined

const isBrowser = typeof window !== "undefined"

 


  return (
    <>
    <Helmet>
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css" />
    <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" /> 
   <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
   <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <link href="https://fonts.googleapis.com/css2?family=Jost&family=Roboto+Slab:wght@400;700&display=swap" rel="stylesheet" />
    </Helmet>
    
    <main >
        <nav class="navbar navbar-expand-lg navbar-light bg-light " style = {navStyle}>
      <Link to = '/' class="navbar-brand" href="#">
      <img src = '/logo.png' style = {logoStyle} className = 'main-logo'></img>
    </Link>

      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" style = {{zIndex:'1'}}>
      <i className="bi bi-list" style = {{fontSize:'40px', color :'#ff0012', zIndex:'-1'}}></i>
      </button>
      
      <div class="collapse navbar-collapse navbar-abs" id="navbarNav">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" style = {{zIndex:'1000',position:'fixed'}}>
      <i className="bi bi-list" style = {hamburgerWhite}></i>
      </button>

        
        <ul class="navbar-nav" style = {linksWrapperStyle}>
          <li className="nav-item active" data-bs-toggle={isBrowser && window.location.pathname === '/' ? 'collapse' : 'false'} data-bs-target={isBrowser && window.location.pathname === '/' ? '.navbar-collapse' : 'false'}>
            <Link to = '/' className="nav-link"  style = {linkStyle} activeStyle = {activeLink}  activeClassName = 'nav-link'>Home </Link>
          </li>
          <li class="nav-item" style = {{borderRight:'1px solid black'}} data-bs-toggle={isBrowser && window.location.pathname === '/' ? 'collapse' : 'false'} data-bs-target={isBrowser && window.location.pathname === '/' ? '.navbar-collapse' : 'false'} >
            <AnchorLink  to = '/#contact-section' title="Contact Me"
              className="stripped nav-link"
              
              
                style = {linkStyle} activeStyle = {activeLink}>Contact</AnchorLink>
          </li>
          <li class="nav-item"  data-bs-toggle={ isBrowser && window.location.pathname === '/portfolio' ? 'collapse' : 'false'} data-bs-target={isBrowser  && window.location.pathname === '/portfolio' ? '.navbar-collapse' : 'false'}>
            <Link to = '/portfolio' className="nav-link" style = {linkStyle} activeStyle = {activeLink} activeClassName="nav-link" >Portfolio</Link>
          </li>
          <li className="nav-item" data-bs-toggle={isBrowser && window.location.pathname === '/' ? 'collapse' : 'false'} data-bs-target={isBrowser && window.location.pathname === '/' ? '.navbar-collapse' : 'false'} >
          <AnchorLink  to = '/#about-section' title="About Me"
              className="stripped nav-link"
              
                 activeStyle = {activeLink}>About</AnchorLink>
          </li>
          <div style  = {{position:'absolute', bottom:'50px'}}>
          <li className="nav-item"  >
          <Link  to = '/#about-section' title="About Me"
              className=" nav-link"
              
                 activeStyle = {activeLink}><i style = {iconStyle} className="bi bi-instagram nav-icon"></i></Link>
          </li>
          <li className="nav-item">
          <Link  to = 'https://facebook.com' title="About Me"
              className=" nav-link"
              
                 activeStyle = {activeLink}><i style  = {iconStyle} className="bi bi-linkedin nav-icon"></i></Link>
          </li>


          </div>
        </ul>
      </div>
    </nav>

        <main>{children}</main>


       <Footer />
        </main>
    </>

  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}


const navStyle = {
  padding:'35px 70px 0px 70px',
}

const linksWrapperStyle = {
  fontSize:'24px',
  margin:'0 auto',
  alignItems:'center'
}

const linkStyle = {
  borderRight:'1px solid black',
  paddingTop:'0px',
  paddingBottom:'0px',
  lineHeight:'20px',
  paddingLeft:'15px',
  paddingRight:'15px',
  textDecoration:'none'
}
const linkStyleNoBorder = {
  paddingTop:'0px',
  paddingBottom:'0px',
  lineHeight:'20px'
}

const activeLink = {
  color:"#FD0D1B"
}

const logoStyle = {
  width:'97px',
  height:'102px'
}

const iconStyle = {
  color:'white',
  fontSize:'38px',
  visibility:'hidden'
}

const hamburgerWhite  = {
 position:'fixed',
 top:'40px',
 right:'40px',
 zIndex:'2000',
 fontSize:'40px',
 color:"white",
 zIndex:'1000'
}

export default Layout
