import React from 'react'

const Footer = () => {
    return (
        <footer style = {footerStyle} className = 'footer'>
            <div className = 'container-fluid footer-container' style = {wrapperStyle}>
                <div className = 'footer-left footer-section' style = {section1Style}>
                    <img src ='/map-icon.svg'style = {mapStyle} className = 'footer-map'></img>
                    <div className = 'footer-address' style = {addressStyle}>
                        <p className = 'm-0'>Knockcroghery,</p>
                        <p className = 'm-0'>Co.Roscommon,</p>
                        <p className='m-0'>Ireland.</p>
                    </div>
                </div>

                <div className = 'footer-right footer-section footer-2' style = {section2Style} >
                <a href = 'https://www.linkedin.com/in/ann-marie-murray-5a58971b1/' target = '_blank'><i className="bi bi-linkedin footer-icon" style = {iconStyle}></i></a>
                <a href = 'https://www.instagram.com/annmaries_designs/?hl=en' target = '_blank'><i className="bi bi-instagram footer-icon" style = {iconStyle}></i></a>
                <a href = 'mailto:annmarie97murray@gmail.com'><i className="bi bi-envelope-fill footer-icon" style = {iconStyle}></i></a>
                </div>

            </div>
        </footer>
    )
}

const footerStyle = {
    paddingLeft:'130px',
    paddingRight:"130px",
    marginTop:'150px',
    backgroundColor:'#FD0D1B',
    height:'350px',
    display:'flex',
    justiftyContent:'center',
    alignItems:'center'
}

const wrapperStyle = {
    display:'flex'
}

const section1Style = {
    width:'50%',
    display:'flex',
}
const section2Style = {
    width:'50%',
    display:'flex',
    justifyContent:'flex-end',
    alignItems:'center'
}

const mapStyle = {
    height:'85px',
    filter:'invert '

}

const addressStyle = {
    color:'white',
    fontSize:'20px',
    paddingLeft:'20px',
    display:'flex',
    flexDirection :'column',
    justifyContent:'center'
}


const iconStyle = {
    fontSize:'36px',
    marginLeft:'30px',
    color:'white'
}
export default Footer
